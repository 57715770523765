<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-if="!loading" my-2>
            <v-layout row wrap my-2 mx-1>
                <v-flex xs12 lg4 xl4 md6>
                    <v-container>
                        <v-layout row wrap>
                            <v-flex xs12>
                                {{ items.item_name }}
                                <v-rating hover length="5" color="orange" :value="items.average_rating" readonly>
                                </v-rating>
                                <br>
                                <div class="pa-2">
                                    <v-btn :href="$url+'/r/'+items.item_id" target="_blank" color="success" outlined rounded block>Preview Item
                                    <v-spacer></v-spacer>
                                    <v-icon>fas fa-eye</v-icon>
                                </v-btn>
                                </div>
                            </v-flex>

                            <!-- <v-flex xs12>
                                <v-card :loading="img_loading" class=" my-3 elevation-0">
                                    <v-card-title primary-title>
                                        Main Image 
                                        <v-spacer></v-spacer>
                                        <v-btn icon color="primary" @click="openFile" v-if="items.item_image">
                                            <v-icon>mdi-pencil-outline</v-icon>
                                        </v-btn>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-img  :src="$api+'/uploads/'+items.item_image"
                                            aspect-ratio="1" width="100%" height="200px" contain>

                                        </v-img>
                                        <v-btn color="secondary" @click="openFile" outlined block x-large
                                            rounded>Tap
                                            to Upload</v-btn>
                                        <input @change="uploadFile" type="file" style="display:none" ref="file"
                                            id="file">
                                    </v-card-text>
                                </v-card>
                                <v-divider></v-divider>
                                <br>
                            </v-flex> -->
                            <v-flex xs12>
                                <v-text-field rounded type="text" v-model="items.item_name"
                                    :label="$store.getters.lang.data.item_name" outlined class="mx-1" filled required>
                                </v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-textarea auto-grow rows="1" rounded type="text" v-model="items.item_description"
                                    :label="$store.getters.lang.data.item_description" outlined class="mx-1" filled required>
                                </v-textarea>
                            </v-flex>
                            <v-flex xs6>
                                <v-select class="mx-1" clearable rounded filled :items="towns" v-model="items.town_id" outlined
                                    item-text="town_full_name" item-value="town_id" :return-object="false"
                                    :label="$store.getters.lang.data.town_name">
                                </v-select>
                            </v-flex>
                            <v-flex xs6>
                                <v-select class="mx-1" clearable rounded filled :items="categories" v-model="items.category_id"
                                    outlined item-text="category_name" item-value="category_id" :return-object="false"
                                    :label="$store.getters.lang.data.category_name">
                                </v-select>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field rounded type="number" v-model="items.item_total_review"
                                    :label="$store.getters.lang.data.item_total_review" outlined class="mx-1" filled required>
                                </v-text-field>
                            </v-flex>
                            <v-flex xs6>
                                <v-text-field rounded type="number" v-model="items.item_custom_sort"
                                    label="Sort" outlined class="mx-1" filled required>
                                </v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-text-field rounded type="text" v-model="items.item_location"
                                    :label="$store.getters.lang.data.item_location" outlined class="mx-1" filled required>
                                </v-text-field>
                            </v-flex>
                            <v-flex xs12>
                                <v-textarea auto-grow rows="1" rounded type="text" v-model="items.item_keywords"
                                    :label="$store.getters.lang.data.item_keywords" outlined class="mx-1" filled required>
                                </v-textarea>
                            </v-flex>
                            <v-flex xs6>
                                <v-select :items="status_list" item-text="text" item-value="value" rounded type="text"
                                    v-model="items.item_status" :label="$store.getters.lang.data.item_status" outlined
                                    class="mx-1" required>
                                </v-select>
                            </v-flex>
                            <v-flex xs6>
                                <v-select :items="comment_status_list" item-text="text" item-value="value" rounded type="text"
                                    v-model="items.item_is_open" label="Comment Status" outlined
                                    class="mx-1" required>
                                </v-select>
                            </v-flex>

                        </v-layout>
                    </v-container>
                </v-flex>
                <v-flex xs12 lg8 xl8 md6>
                    <v-container>
                        <v-layout row wrap mx-2>
                            <v-flex xs12 lg6 xl6 md6>
                                <v-flex xs12>
                                    <v-card outlined>
                                        <v-card-title primary-title>
                                            Map Settings
                                        </v-card-title>
                                        <v-card-text>
                                            <v-text-field
                                        label="Latitude"
                                        id="id"
                                        v-model="items.item_lat"
                                    ></v-text-field>
                                    <v-text-field
                                        label="Longtuitude"
                                        id="id"
                                        v-model="items.item_long"
                                    ></v-text-field>
                                    <v-btn color="success" block @click="renderMap">check map</v-btn>
                                        </v-card-text>
                                    </v-card>
                                    <br>
                                </v-flex>
                                <v-card class="elevation-0">
                                    <div id="map">

                                    </div>
                                </v-card>
                            </v-flex>
                            <v-flex xs12 lg6 xl6 md6>
                                <v-card width="100%" class="mx-1 elevation-0">
                                    <v-card-title primary-title>
                                        Badges
                                    </v-card-title>
                                    <v-card-text>
                                        <form @submit.prevent="addBadge">
                                            <v-layout row wrap mx-1>
                                                <v-flex xs10>
                                                    <v-select item-value="badge_id" :return-object="false" :items="badges" item-text="badge_name" label="New Badge Name" v-model="badge_name" required>
                                                    </v-select>
                                                </v-flex>
                                                <v-flex xs2>
                                                    <v-btn color="primary" class="mx-auto" :disabled="!badge_name"
                                                        x-large type="submit" icon rounded>
                                                        <v-icon>fas fa-plus</v-icon>
                                                    </v-btn>
                                                </v-flex>
                                            </v-layout>
                                            <br>
                                            <v-divider></v-divider>
                                            <br>
                                        </form>
                                        <v-layout row wrap mx-1 v-for="(i, index) in items.item_badges" :key="index">
                                            <v-flex xs8>
                                                <v-select item-value="badge_id" :return-object="false" :items="badges" item-text="badge_name" label="Badge Name" v-model="i.badge_id" required>
                                                    </v-select>
                                            </v-flex>
                                            <v-flex xs2>
                                                <v-btn color="secondary" class="mx-auto" :disabled="!i.badge_id"
                                                    x-large @click="updateBadge(i , index)" icon rounded>
                                                    <v-icon>mdi-pencil-outline</v-icon>
                                                </v-btn>
                                            </v-flex>
                                            <v-flex xs2>
                                                <v-btn color="error" class="mx-auto" x-large
                                                    @click="deleteBadge(i.item_badge_id)" icon rounded>
                                                    <v-icon>mdi-delete-outline</v-icon>
                                                </v-btn>
                                            </v-flex>
                                        </v-layout>
                                    </v-card-text>
                                </v-card>
                            </v-flex>
                            <v-flex xs12>
                                <v-container>
                                    <v-subheader v-if="items.item_images.length > 0">{{items.item_name}}'s Gallery
                                    </v-subheader>
                                    <v-layout row wrap>
                                        <v-flex xs6 v-for="(i, index) in items.item_images" :key="index">
                                            <v-card class="ma-1">
                                                <v-card-text>
                                                    <v-img width="100%" height="250" aspect-ratio="1" contain
                                                        :src="$api+'/uploads/'+i.item_image_path"></v-img>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-btn block text rounded @click="deleteGallery(i.item_image_id)"
                                                        color="error" dark>Delete Image</v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-flex>
                                        <v-flex xs12>
                                            <v-card class="mx-1 elevation-0 my-3" outlined >
                                                <v-card-text>
                                                    Add New Image to {{items.item_name}}'s Gallery
                                                </v-card-text>
                                                <v-card-text>
                                                    <v-btn color="secondary" :disabled="items.item_images.length >= 5" @click="openGalleryFile" outlined block
                                                        x-large rounded>Tap
                                                        to Upload</v-btn>
                                                    <input @change="uploadGallery" type="file" style="display:none"
                                                        ref="gallery_file" id="gallery_file">
                                                </v-card-text>

                                            </v-card>
                                        </v-flex>
                                    </v-layout>
                                </v-container>
                            </v-flex>
                            <v-flex xs12 class="px-1">
                                <v-btn color="primary" class="mt-2" block rounded @click="update_items" x-large>
                                    {{$store.getters.lang.data.update_btn}}</v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-flex>
            </v-layout>

        </v-container>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>

<script>
    import axios from 'axios'
    import controller from './../../controller/items'
    import badge_controller from './../../controller/item_badges'
    import image_controller from './../../controller/item_images'
    export default {
        data() {
            return {
                items: {},
                id: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                img_loading: false,
                gallery_loading: false,
                badge_name: '',

            }
        },
        computed: {

            categories() {
                return this.$store.getters.categories
            },

            towns() {
                return this.$store.getters.towns
            },
            badges() {
                return this.$store.getters.badges
            },

            users() {
                return this.$store.getters.users
            },
            status_list() {
                return [{
                        text: 'Active',
                        value: 'enable'
                    },
                    {
                        text: 'Inactive',
                        value: 'disable'
                    },
                    {
                        text: 'Pending',
                        value: 'pending'
                    }
                ]
            },
            comment_status_list() {
                return [
                    {
                        text: 'QFL',
                        value: 'qfl'
                    },
                    {
                        text: 'Open',
                        value: 'open'
                    }
                ]
            },


        },
        methods: {
            
            uploadGallery(e) {
                if (!e.target.files[0]) {
                    return
                }
                this.img_loading = true
                let formData = new FormData();
                formData.append('item_id', this.id);
                formData.append('file', e.target.files[0]);
                axios.post("items/uploadGalleryImage.php",
                        formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then((data) => {
                        if (data.data.status == 'ok') {
                            this.get_items()
                        }
                        this.img_loading = false
                    })
                    .catch(() => {
                        this.img_loading = false
                        console.log('FAILURE!!');
                    });
            },
            deleteGallery(id) {
                image_controller.delete_item_images(id)
                    .then((data) => {
                        if (data.data) {
                            this.items.item_images = this.items.item_images.filter(i => i.item_image_id != id)
                        }
                    })

            },
            updateBadge(i, index) {
                badge_controller.update_item_badges(i).then(res => {
                    if (res.data) {
                        this.snackbar = {
                            value: true,
                            text: 'Badge Updated',
                            color: 'success'
                        }
                        this.items.item_badges[index].item_badge_name = i.item_badge_name
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Badge Not Updated',
                            color: 'error'
                        }
                    }

                    // this.get_items()
                })
            },
            deleteBadge(id) {
                badge_controller.delete_item_badges(id).then(response => {
                    if (response.data) {
                        this.snackbar = {
                            value: true,
                            text: 'Successfully Deleted',
                            color: 'success'
                        }
                        this.items.item_badges = this.items.item_badges.filter(i => i.item_badge_id !== id)
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Failed to Delete',
                            color: 'error'
                        }

                    }
                    // this.get_items()
                })
            },
            addBadge() {
                badge_controller.add_item_badges({
                    item_id: this.id,
                    badge_id: this.badge_name
                }).then(r => {
                    this.badge_name = ''
                    if (r.data) {
                        this.badge_name = ''
                        this.snackbar = {
                            value: true,
                            text: 'Badge Added',
                            color: 'success'
                        }
                        this.items.item_badges.push(r.data.new_data)
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Error',
                            color: 'error'
                        }
                    }
                    // this.get_items()
                })
            },
            renderMap() {

                const map = new google.maps.Map(document.getElementById('map'), {
                    center: {
                        lat: parseFloat(this.items.item_lat),
                        lng: parseFloat(this.items.item_long),
                    },
                    zoom: 6,
                    maxZoom: 25,
                    minZoom: 1,
                    zoomControl: false,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true
                })
                let marker = new google.maps.Marker({
                    position: {
                        lat: parseFloat(this.items.item_lat),
                        lng: parseFloat(this.items.item_long)
                    },
                    draggable: true,
                    icon: {
                        url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png"
                    },
                    map
                })
                marker.addListener('dragend', () => {
                    this.items.item_lat = marker.getPosition().lat()
                    this.items.item_long = marker.getPosition().lng()
                })

            },
            openFile() {
                this.$refs.file.click()
            },
            openGalleryFile() {
                this.$refs.gallery_file.click()
            },
            uploadFile(e) {
                if (!e.target.files[0]) {
                    return
                }
                this.img_loading = true
                let formData = new FormData();
                formData.append('item_id', this.id);
                formData.append('file', e.target.files[0]);
                axios.post("items/uploadMainImage.php",
                        formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    ).then((data) => {
                        if (data.data.status == 'ok') {
                            this.get_items()
                        }
                        this.img_loading = false
                    })
                    .catch(() => {
                        this.img_loading = false
                        console.log('FAILURE!!');
                    });
            },
            get_items() {
                this.loading = true
                controller.getOne(this.id).then(r => {
                    this.items = r.data.row
                    this.loading = false
                    setTimeout(() => {
                        this.renderMap()
                    }, 1000);
                }).catch(e => {
                    console.log(e);
                })
            },
            update_items() {
                controller.update_items(this.items).then(r => {
                    if (r.data) {
                        this.snackbar = {
                            value: true,
                            text: 'Updated',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Not Updated',
                            color: 'error'
                        }
                    }
                })
            },

        },
        mounted() {
            this.id = this.$route.params.id
            this.get_items()
        },
    }
</script>
<style>
    #map {
        width: 100%;
        height: 500px;
    }
</style>