
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_item_images(params)  {
            return await axios.post('item_images/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_item_images()  {
            // return await axios.get('item_images/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_item_images')
        },
        async delete_item_images(item_image_id)  {
            return await axios.post('item_images/delete.php' , {
                item_image_id : item_image_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(item_image_id)  {
            return await axios.post('item_images/readOne.php' , {
                item_image_id : item_image_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_item_images(params)  {
            return await axios.post('item_images/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_item_images(params)  {
            return await axios.post('item_images/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
