
    import axios from 'axios'
    import store from '../store/index.js'

    export default {
        async add_item_badges(params)  {
            return await axios.post('item_badges/create.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async get_item_badges()  {
            // return await axios.get('item_badges/read.php')
            // .then(r =>{
            //     return r
            // })
            // .catch(e =>{
            //     console.log(e);
            // })
            store.commit('get_item_badges')
        },
        async delete_item_badges(item_badge_id)  {
            return await axios.post('item_badges/delete.php' , {
                item_badge_id : item_badge_id
            })
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async getOne(item_badge_id)  {
            return await axios.post('item_badges/readOne.php' , {
                item_badge_id : item_badge_id
            })
            .then(r =>{
                return r
                
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async update_item_badges(params)  {
            return await axios.post('item_badges/update.php' , params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
        async report_item_badges(params)  {
            return await axios.post('item_badges/report.php',params)
            .then(r =>{
                return r
            })
            .catch(e =>{
                console.log(e);
            })
        },
    }
